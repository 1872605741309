<!-- eslint-disable vue/no-v-html -->
<template>
  <v-card flat>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-alert
      v-if="alert"
      outlined
      type="error"
      color="#C70039"
      class="mt-4"
      transition="scale-transition"
      style="margin-left: 16px; margin-right: 16px;"
    >
      <h3>Error adding users:</h3>
      <v-row>
        <v-col class="grow">
          <p v-html="printNotCreatedUser()" />
        </v-col>
        <v-col
          class="shrink"
          align-self="end"
        >
          <v-btn
            color="primary"
            @click="modifyNotAdeddAppUsers"
          >
            Modify
          </v-btn>
        </v-col>
        <v-col
          class="shrink"
          align-self="end"
        >
          <v-btn
            dark
            color="#C70039"
            @click="alert = false"
          >
            Close
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
    <v-data-table
      id="app-user-table"
      v-model="selected"
      :headers="app_user.headers"
      :items="app_user.aUsers"
      :search="search"
      :sort-by="'username'"
      :sort-desc="false"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 15, 50, 100, -1]
      }"
      class="elevation-1"
      show-select
      item-key="username"
    >
      <template v-slot:top>
        <v-toolbar
          flat
          color="white"
        >
          <v-toolbar-title>APP USERS</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          />
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          />
          <v-spacer />
          <template>
            <v-btn
              color="primary"
              width="50"
              @click="_switchAUState(selected)"
            >
              State
            </v-btn>
          </template>
          <v-spacer />
          <template>
            <cu-app-user-dialog
              :app-user-form="appUserForm"
              @formAppUser="formAppUser"
              @showAlert="showAlert($event)"
            />
          </template>
        </v-toolbar>
      </template>
      <template v-slot:[`item._state`]="{ item }">
        <v-chip
          :color="item._state === true ? 'color_green' : '#C70039'"
        />
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="pencilButtonEvent(item)"
        >
          mdi-pencil
        </v-icon>
        <delete
          :id="item.name"
          @deleted="deleteItem(item)"
        />
      </template>
      <template v-slot:no-data>
        <span color="primary"> No app users yet. Add some! </span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    cuAppUserDialog: () =>
      import('@/components/customer/app-user/CUAppUserDialog'),
    delete: () => import('@/components/utils/Delete')
  },
  data() {
    return {
      overlay: false,
      alert: false,
      showCUDialogAU: false,
      search: '',
      selected: [],
      appUserForm: {
        username: '',
        _name: '',
        surname: '',
        email: '',
        phone: '',
        language: '',
        user_map_style: '',
        _state: false,
        cognito_user_created: false
      }
    };
  },
  computed: {
    ...mapState(['app_user'])
  },
  methods: {
    ...mapActions(['switchAUState', 'deleteTableAppUser', 'dialogAction', 'storeIsModifingAppUser', 'storeIsModifingNotCreatedAppUsers', 'storeAppUsers', 'storeDisable', 'setNotCreatedAppUsers']),
    async deleteItem(app_user) {
      this.overlay = true;
      await this.deleteTableAppUser(app_user);
      //this.$emit("closeDialog");
      await this.storeAppUsers(this.app_user.crm_id);
      this.overlay = false;
    },
    pencilButtonEvent(item) {
      this.storeIsModifingAppUser(true);
      this.storeDisable(true);
      this.showCUDialogAU = true;
      this.app_user.storeAppUserForm = item;
      this.dialogAction(this.showCUDialogAU);
      this.appUserForm = JSON.parse(JSON.stringify(item));
    },
    async _switchAUState(selected) {
      this.overlay = true;
      await this.switchAUState(selected);
      await this.storeAppUsers(this.app_user.crm_id);
      this.overlay = false;
    },
    formAppUser() {
      this.appUserForm.username = '';
      this.appUserForm._name = '';
      this.appUserForm.surname = '';
      this.appUserForm.email = '';
      this.appUserForm.phone = '';
      this.appUserForm.language = '';
      this.appUserForm.user_map_style = '';
      this.appUserForm._state = false;
      this.appUserForm.cognito_user_created = false;
    },
    printNotCreatedUser() {
      let userList = '';

      if (this.app_user.createAppUser.not_created) {
        this.app_user.createAppUser.not_created.forEach((notCreated, index) => {
          if (index < this.app_user.createAppUser.not_created.length - 1) {
            userList += notCreated.username + ', ';
          }
        });
        userList += this.app_user.createAppUser.not_created[this.app_user.createAppUser.not_created.length - 1].username + '.<br>';
        userList += this.app_user.createAppUser.not_created.length == 1 ? 'Username alredy in use. Edit it and try again.' : 'Usernames alredy in use. Edit them and try again.';
      }

      return userList;
    },
    modifyNotAdeddAppUsers() {
      this.storeIsModifingNotCreatedAppUsers(true);

      if (this.app_user.createAppUser.not_created.length == 1) {
        this.appUserForm = JSON.parse(JSON.stringify(this.app_user.createAppUser.not_created[0]));
        this.appUserForm.cognito_user_created = false;
        delete this.appUserForm.app_user_id;
      }

      this.dialogAction(true);
    },
    showAlert(value) {
      this.alert = value;
    }
  }
};
</script>
